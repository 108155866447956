import screen3 from "../images/newProductPage/hungarian/3.webp";
import screen4 from "../images/newProductPage/hungarian/4.webp";
import screen5 from "../images/newProductPage/hungarian/5.webp";
import screen6 from "../images/newProductPage/hungarian/4.webp";
import screen1 from "../images/newProductPage/hungarian/1.webp";
import screen2 from "../images/newProductPage/hungarian/2.webp";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const hungary = {
  navbar: {
    data: [
      { title: "Tanfolyam rendelése", path: "/course" },
      { title: "Naplózás", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "Rólunk", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "hu",
      hero: {
        languages: [
          "angolul",
          "spanyolul",
          "németül",
          "hollandul",
          "olaszul",
          "oroszul",
          "lengyelül",
          "franciául",
          "portugálul",
        ],
        title: "Bátran,",
        prefix: "magabiztosan beszélj",
        subtitle:
          "Szerezzen nyelvtudást a valós példákra épülő kurzusunk segítségével!",
        buttonText: "Rendelje meg most!",
      },
      learning: {
        title: "Milyen a kurzus?",
        config: [
          {
            image: screen1,
            category: "Tanulja meg a nyelvet a legegyszerűbb módon",
            descriptions: [
              {
                bold: "Tucatnyi lecke",
                rest: "a mindennapi kommunikáció példáival.",
              },
              {
                bold: "Gyakorlati kifejezések",
                rest: "anyanyelvi beszélők által tervezve.",
              },
              {
                bold: "Gyakorlatok,",
                rest: "amelyek hatékonyan fejlesztik az olvasási, hallgatási és beszédkészséget.",
              },
            ],
          },
          {
            image: screen2,
            category: "Gyakorlati leckék",
            description:
              "Minden tanulási szint tucatnyi leckéből áll, amelyek az Ön igényeihez igazodnak.",
          },
          {
            image: screen3,
            category: "Feladatok",
            description:
              "Gyakorlataink hatékonyan támogatják az anyag ismétlését és javítják a memóriát.",
          },
          {
            image: screen4,
            category: "Szavak, kifejezések és mondatok",
            description:
              "Minden lecke gondosan kiválasztott képeket tartalmaz, amelyek intuitívvá teszik a tanulási folyamatot.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angol" },
          { image: new_french, lang: "Francia" },
          { image: new_spanish, lang: "Spanyol" },
          { image: new_niderland, lang: "Holland" },
          { image: new_germany, lang: "Német" },
          { image: new_italy, lang: "Olasz" },
          { image: new_russia, lang: "Orosz" },
          { image: new_portugal, lang: "Portugál" },
          { image: new_polish, lang: "Lengyel" },
        ],
        title: "Elérhető nyelvek:",
        description:
          "Készüljön fel a következő utazására vagy adjon nyelvtudást az önéletrajzához.",
      },
      expertSection: {
        title: "Az Ön nyelvi szakértője",
        firstSectionTitle: "Támogatás egy kattintásnyira",
        firstSectionDescription: "Kapjon teljes támogatást a tanulás során!",
        secondSectionTitle: "Tegyen fel kérdéseket, és kapjon válaszokat",
        secondSectionDescription:
          "Bármilyen kérdést feltehet közvetlenül szakértőjének.",
        thirdSectionTitle: "Egyéni megközelítés minden lépésben",
        thirdSectionDescription:
          "A szakértő tanácsainak felhasználása felgyorsítja a tanulási folyamatot.",
      },
      courseValue: {
        title: "Szerezzen többet az eLingóval",
        subtitle:
          "Nemcsak nyelvtanulás – teljes hozzáférést kap az eszközökhöz.",
        buttonText: "Rendelje meg most!",
        config: [
          {
            title: "Az Ön életmódjához igazított tanulás",
            sections: [
              {
                text: "Tanuljon bárhol és bármikor a platformunknak köszönhetően.",
              },
              {
                text: "Rövid leckék tökéletesen illeszkednek a napi rutinjába.",
              },
            ],
          },
          {
            title: "Motiváció minden lépésben",
            sections: [
              {
                text: "Tartsa fenn a motivációját a mi követési rendszerünkkel.",
              },
              {
                text: "Minden tanulási nap közelebb visz a folyékony beszédhez.",
              },
            ],
          },
          {
            title: "Teljes rugalmasság és testreszabás",
            sections: [
              {
                text: "Alakítsa a kurzust saját szintjéhez és tanulási üteméhez.",
              },
              {
                text: "Fókuszáljon arra, ami a leginkább érdekli.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Küldetésünk",
      description: `Hiszünk abban, hogy a nyelv hidat képez a kultúrák között, küldetésünk pedig a kommunikációs akadályok leküzdése és az emberek összehozása a nyelvoktatáson keresztül.`,
    },
    why: {
      title: "Miért mi?",
      config: [
        {
          title: "Innovatív oktatási módszerek",
          image: dlaczego1,
          description:
            "Kifinomult és gyakorlatban tesztelt tanulási algoritmusunk a tananyagokat a jelenlegi tudásszintjéhez igazítja és a hatékony tanítás útján bővíti azt.",
        },
        {
          title: "Nyelvek széles választéka",
          image: dlaczego2,
          description:
            "A világ legnépszerűbb nyelveit tanulhatja a tanfolyamainkon. Ez lehetővé teszi az igényeinek megfelelő nyelv kiválasztását.",
        },
        {
          title: "Oktatás szórakoztató módon",
          image: dlaczego3,
          description:
            "Platformunk szórakoztató és élvezetes élménnyé teszi a nyelvtanulást, így Ön is motivált lesz a nyelvtanulásra.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Személyre szabás és hozzáférés",
          description:
            "A hatékony gépi tanulási algoritmusunk folyamatosan azon dolgozik, hogy minden egyes személy számára megfelelő szintű tananyagot biztosítson. Az eLingo lehetővé teszi a tanulók számára, hogy önállóan fedezzenek fel nyelvi szabályszerűségeket görcsösség nélkül, hasonlóan, ahogy gyermekkorban tanulja az anyanyelvét.",
          image: zdjecie1,
        },
        {
          title: "A technológia és a tudomány kombinációja",
          description:
            "Az eLingo a technológia és a tudomány merész kombinációját használja, hogy cselekvésre ösztönözze az elmét. Számtalan szó- és kifejezéslista ismétlése unalmas lehet és az agya nem képes azt feldolgozni. A mi módszerünk azonban izgalmas és hatékony, amely arra ösztönzi, hogy a megszerzett tudást meghatározott pillanatokban különböző módon használja fel. Az agya értékelni fogja ezt a megoldást!",
          image: zdjecie2,
        },
        {
          title: "Közösség és értékek",
          description:
            "A nyelvek segítik az embereket, hogy jobban megismerjék egymást. A nyelvtanulás elősegíti a megértést. Az eLingo közösség a világ minden tájáról összehozza az embereket. Ambiciózusok vagyunk és mindig feszegetjük a határokat. Hiszünk abban, hogy a nyelvtanulás szórakoztató is lehet és nagy kíváncsisággal fedezünk fel új kultúrákat a nyelven keresztül.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Érdekli a tanfolyam? ",
      title1: "Szeretné, hogy több információt?",
      subtitle: "Hagyja meg a telefonszámát - visszahívjuk. ",
      subtitle1:
        "Ha már rendelkezik fiókkal, kérjük, töltse ki az alábbi mezőket:",
      button1: "Név és vezetéknév",
      button2: "Telefonszám",
      button3: "E-mail",
      buttonSub: "Küldje el a",
      checkOne:
        "Az űrlap elküldésével elfogadom a feltételeket, és kijelentem, hogy elolvastam a következőket",
      checkOneTwo: "a",
      checkTwo:
        "Hozzájárulok, hogy marketing célokra felvegyék velem a kapcsolatot",
      textOne: "Tájékoztatjuk, hogy",
      textTwo:
        "Az Ön személyes adatainak kezelője az EOU Sp. z o.o., társaság, székhelye Varsó, Młynarska 42 / 115, 01-171, bejegyezve a varsói kerületi bíróság által vezetett vállalkozói nyilvántartásba KRS5273057993, REGON: 525396900, NIP5273057993. Az Ön személyes adatait a kapcsolatfelvételi űrlapon történő megkeresésének feldolgozásával vagy a megkötött szerződés teljesítésével kapcsolatban dolgozzuk fel. Azzal, hogy kapcsolatba lép velünk, Ön önkéntesen hozzájárul személyes adatainak a fenti célokból történő feldolgozásához. Az Ön adatainak feldolgozásával kapcsolatban Önnek joga van a hozzáféréshez, a helyesbítéshez, a törléshez vagy a feldolgozás korlátozásához, a feldolgozás elleni tiltakozáshoz és az adathordozhatósághoz. Ön jogosult arra is, hogy hozzájárulását bármikor visszavonja, de a hozzájárulás visszavonása nem érinti a visszavonás előtti hozzájárulás alapján végzett adatkezelés jogszerűségét. Önnek joga van ahhoz is, hogy panaszt tegyen a felügyeleti hatóságnál. Az adatok megadása természetesen önkéntes, de szükséges lehet egy megkeresés megválaszolásához vagy egy szerződés teljesítéséhez. A személyes adatokat a fenti célokhoz szükséges ideig tároljuk, de nem az általános jog szerinti követelések elévülési idejéig. Kérjük, figyelmesen olvassa el az adatvédelmi szabályzatot",
      buttonMore: "Olvass tovább",
      buttonLess: "Olvass kevesebbet",
      subOne: "feltételek",
      subTwo: "és adatvédelmi szabályok.",
      buttonTXT: "Köszönöm",
      rulesLink: "/Regulations_HU.pdf",
      privacyLink: "/Privacy_HU.pdf",
      product: "eh",
      market: "hu",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "hu-en",
      server_link: "https://cms.eou-edu.eu/integration/lead-from-landing",
      currency: "huf",
      price: 29000,
    },
  },
  footer: {
    title: "Csatlakozz hozzánk itt:",
    privacy: "Adatvédelem",
    privacyLink: "/Privacy_HU.pdf",
    rules: "Szolgáltatási feltételek",
    rulesLink: "/Regulations_HU.pdf",
  },
};
