import new_CZgland from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen1 from "../images/newProductPage/czech/1.png";
import screen2 from "../images/newProductPage/czech/2.png";
import screen3 from "../images/newProductPage/czech/3.png";
import screen4 from "../images/newProductPage/czech/4.png";
import screen5 from "../images/newProductPage/czech/5.png";
import screen6 from "../images/newProductPage/czech/6.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const czech = {
  navbar: {
    data: [
      { title: "Objednat kurz", path: "/course" },
      { title: "Přihlásit se", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "cs",
      hero: {
        languages: [
          "v angličtině",
          "v španělštině",
          "v němčině",
          "v holandštině",
          "v italštině",
          "v ruštině",
          "v polštině",
          "v francouzštině",
          "v portugalštině",
        ],
        title: "Mluvte odvážněji,",
        prefix: "sebejistě",
        subtitle: `Získejte jazykové dovednosti díky našemu kurzu založenému na příkladech z reálného života!`,
        buttonText: "Objednat nyní!",
      },
      learning: {
        title: "Jak vypadá kurz?",
        config: [
          {
            category: "Učte se jazyk nejjednodušším způsobem",
            image: screen1,
            descriptions: [
              {
                bold: "Desítky lekcí",
                rest: "s příklady založenými na každodenní komunikaci.",
              },
              {
                bold: "Praktické fráze",
                rest: "navržené rodilými mluvčími.",
              },
              {
                bold: "Cvičení,",
                rest: "která efektivně rozvíjejí čtení, poslech i mluvení.",
              },
            ],
          },
          {
            image: screen2,
            category: "Praktické lekce",
            description:
              "Každá úroveň výuky se skládá z desítek lekcí přizpůsobených vašim potřebám. Pokud neznáte svou úroveň, začněte od základů – naučte se pozdravy, dny v týdnu a jak si objednat jídlo v restauraci. Každá lekce je založena na příkladech z každodenního života.",
          },
          {
            image: screen3,
            category: "Úkoly",
            description:
              "Ať už denně luštíte křížovky, nebo se takovým aktivitám raději vyhýbáte, naše cvičení efektivně podporují opakování materiálu a zlepšují paměť.",
          },
          {
            image: screen4,
            category: "Slova, fráze a výrazy",
            description:
              "Každá lekce obsahuje pečlivě vybrané obrázky, které činí proces učení intuitivním a kontextovým. Témata pokrývají široký rozsah slovní zásoby z každodenního života i cestování, což vám umožňuje plynulou komunikaci.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_CZgland, lang: "Angličtina" },
          { image: new_french, lang: "Francouzština" },
          { image: new_spanish, lang: "Španělština" },
          { image: new_niderland, lang: "Holandština" },
          { image: new_germany, lang: "Němčina" },
          { image: new_italy, lang: "Italština" },
          { image: new_russia, lang: "Ruština" },
          { image: new_portugal, lang: "Portugalština" },
          { image: new_polish, lang: "Polština" },
        ],
        title: "Dostupné jazyky:",
        description: `PPřipravte se na svou další cestu, přidejte jazykové dovednosti do svého životopisu nebo se ponořte do nové kultury. Naše kompletní nabídka lekcí eLingo vám poskytne vše, co potřebujete k tomu, abyste mohli plynně komunikovat bez ohledu na úroveň pokročilosti.
`,
      },
      expertSection: {
        title: "Váš jazykový expert",
        firstSectionTitle: "Podpora na dosah ruky",
        firstSectionDescription: `Získejte plnou podporu při studiu! Úkoly v kurzu jsou kontrolovány
            zkušenými jazykovými experty, kteří dohlížejí na váš pokrok. Díky
            tomu máte jistotu, že každý krok na cestě k jazykové plynulosti je
            správně nasměrován.`,
        secondSectionTitle: "Ptejte se a dostávejte odpovědi",
        secondSectionDescription: `Máte pochybnosti? Každou otázku můžete položit přímo svému
            expertovi. Dostanete odbornou odpověď přímo do své schránky na
            online platformě.`,
        thirdSectionTitle: "Individuální přístup na každém kroku",
        thirdSectionDescription: `Využití rad jazykového experta nejen urychlí vaše učení, ale učiní
            ho také efektivnějším. S individuálním přístupem a praktickými
            radami získáte jistotu a váš pokrok bude ještě rychlejší!`,
      },
      courseValue: {
        title: "Získejte více s eLingo",
        subtitle: `Nejen jazykové učení – získejte plný přístup k nástrojům, které vám
        pomohou dosáhnout svého cíle rychleji než kdykoliv předtím!`,
        buttonText: "Objednat nyní!",
        config: [
          {
            title: "Výuka přizpůsobená vašemu životnímu stylu ",
            sections: [
              {
                text: "Učte se, kde chcete a kdy chcete, díky platformě dostupné na různých zařízeních.",
              },
              {
                text: "Krátké lekce dokonale zapadnou do vašeho každodenního harmonogramu, ať už máte 5 minut nebo hodinu.",
              },
            ],
          },
          {
            title: "Motivace na každém kroku",
            sections: [
              {
                text: "Udržujte svou řadu dnů učení díky našemu systému sledování po sobě jdoucích dní.",
              },
              {
                text: "Každý den učení je krok blíže k jazykové plynulosti. Nenechte svůj pokrok přerušit!",
              },
            ],
          },
          {
            title: "Plná flexibilita a personalizace",
            sections: [
              {
                text: "Přizpůsobte si kurz své úrovni a tempu učení – začněte od základů nebo přeskočte na pokročilejší témata. ",
              },
              {
                text: "Zaměřte se na to, co vás nejvíce zajímá – opakujte témata týkající se cestování, práce nebo každodenních situací.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Poslání",
      description: `Věříme, že jazyk tvoří most mezi kulturami, a naším posláním je překonávat komunikační bariéry a spojovat lidi prostřednictvím vzdělávání v oblasti jazyků.`,
    },
    why: {
      title: "Proč my?",
      config: [
        {
          title: "Inovativní vzdělávací metody",
          image: dlaczego1,
          description:
            "Náš sofistikovaný a v praxi ověřený učební algoritmus přizpůsobuje materiály vaší aktuální úrovni znalostí a rozšiřuje je prostřednictvím efektivního vyučování.",
        },
        {
          title: "Široká škála jazyků",
          image: dlaczego2,
          description:
            "Nabízíme kurzy ve světově nejpopulárnějších jazycích. To vám umožní vybrat si jazyk, který vyhovuje vašim potřebám.",
        },
        {
          title: "Vzdělávání zábavnou formou",
          image: dlaczego3,
          description:
            "Naše platforma činí učení jazyků zábavným a příjemným zážitkem, takže budete motivováni k učení jazyků.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizace a dostupnost",
          description:
            "Náš efektivní algoritmus strojového učení neustále pracuje na poskytování odpovídajícího materiálu pro každého jednotlivce. eLingo umožňuje studentům objevovat vzory sami, aniž by se museli soustředit na pravidla jazyka – stejně jako jste se naučili svůj mateřský jazyk v dětství.",
          image: zdjecie1,
        },
        {
          title: "Kombinace technologie a vědy",
          description:
            "eLingo využívá odvážnou kombinaci technologie a vědy k stimulaci mysli k akci. Opakování nesčetných seznamů slov a výrazů je nudné a váš mozek to ví. Naše metoda je vzrušující a efektivní, nutí vás používat získané znalosti různými způsoby ve specifických okamžicích - váš mozek ocení toto řešení!",
          image: zdjecie2,
        },
        {
          title: "Komunita a hodnoty",
          description:
            "Jazyky pomáhají lidem seznamovat se. Učení jazyků usnadňuje porozumění. Komunita eLingo spojuje lidi z celého světa. Jsme ambiciózní a neustále posouváme hranice. Věříme, že učení jazyků může být zábavné a jsme zvědaví na prozkoumávání nových kultur prostřednictvím jazyka.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Máte zájem o kurz? ",
      title1: "Chtěli byste více informací?",
      subtitle: "Zanechte své telefonní číslo - zavoláme vám zpět.",
      subtitle1: "Pokud již máte účet, vyplňte následující pole:",
      button1: "Jméno a příjmení",
      button2: "Telefonní číslo",
      button3: "Email",
      buttonSub: "Odeslat",
      checkOne:
        "Odesláním tohoto formuláře souhlasím s podmínkami a prohlašuji, že jsem si přečetl následující",
      checkOneTwo: "to",
      checkTwo:
        "Souhlasím s tím, aby se mnou bylo kontaktováno za účelem marketingu",
      textOne: "Upozorňujeme, že",
      textTwo:
        "Správcem vašich osobních údajů je společnost EOU Sp. z o.o., se sídlem ve Varšavě, Młynarska 42 / 115, 01-171, zapsaná v rejstříku podnikatelů vedeném okresním soudem ve Varšavě pod KRS5273057993, REGON: 525396900, NIP5273057993. Vaše osobní údaje budou zpracovávány v souvislosti s dotazem na kontaktní formulář nebo s provedením uzavřené smlouvy. Kontaktováním nás dobrovolně souhlasíte se zpracováním vašich osobních údajů pro výše uvedené účely. Máte právo na přístup, opravu, smazání nebo omezení zpracování vašich údajů, právo vznést námitku proti zpracování a právo na přenositelnost údajů. Máte také právo kdykoli odvolat svůj souhlas, avšak odvolání souhlasu neovlivní zákonnost zpracování na základě souhlasu před jeho odvoláním. Máte také právo podat stížnost dozorovému orgánu. Poskytnutí údajů je dobrovolné, ale může být nezbytné pro odpověď na dotaz nebo provedení smlouvy. Osobní údaje budou uchovávány po dobu nezbytnou pro výše uvedené účely, avšak ne déle než dobu promlčení nároků podle obecného práva. Přečtěte si prosím pečlivě zásady ochrany osobních údajů",
      buttonMore: "Číst více",
      buttonLess: "Číst méně",
      subOne: "podmínky",
      subTwo: "a pravidla ochrany soukromí.",
      buttonTXT: "Děkuji",
      rulesLink: "/Regulations_CZ.pdf",
      privacyLink: "/Privacy_CZ.pdf",
      product: "ec",
      market: "cz",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "cs-en",
      server_link: "https://cms.eou-edu.eu/integration/lead-from-landing",
      currency: "czk",
      price: 1960,
    },
  },
  footer: {
    title: "Připojte se k nám na:",
    privacy: "Ochrana soukromí",
    privacyLink: "/Privacy_CZ.pdf",
    rules: "Podmínky služby",
    rulesLink: "/Regulations_CZ.pdf",
  },
};
