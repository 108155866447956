import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen1 from "../images/newProductPage/croatian/1.png";
import screen2 from "../images/newProductPage/croatian/2.png";
import screen3 from "../images/newProductPage/croatian/3.png";
import screen4 from "../images/newProductPage/croatian/4.png";
import screen5 from "../images/newProductPage/croatian/5.png";
import screen6 from "../images/newProductPage/croatian/6.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const croatian = {
  navbar: {
    data: [
      { title: "Naručite tečaj", path: "/course" },
      { title: "Prijava", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "O nama", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "hr",
      hero: {
        languages: [
          "na engleskom",
          "na španjolskom",
          "na njemačkom",
          "na nizozemskom",
          "na talijanskom",
          "na ruskom",
          "na poljskom",
          "na francuskom",
          "na portugalskom",
        ],
        title: "Govorite s više samopouzdanja,",
        prefix: "samopouzdano",
        subtitle:
          "Steknite jezične vještine putem našeg tečaja temeljenog na stvarnim primjerima!",
        buttonText: "Naručite sada!",
      },
      learning: {
        title: "Kako izgleda tečaj?",
        config: [
          {
            image: screen1,
            category: "Naučite jezik na najjednostavniji način",
            descriptions: [
              {
                bold: "Deseci lekcija",
                rest: "s primjerima temeljenima na svakodnevnoj komunikaciji.",
              },
              {
                bold: "Praktične fraze",
                rest: "dizajnirane od strane izvornih govornika.",
              },
              {
                bold: "Vježbe,",
                rest: "koje učinkovito razvijaju čitanje, slušanje i govor.",
              },
            ],
          },
          {
            image: screen2,
            category: "Praktične lekcije",
            description:
              "Svaka razina nastave sastoji se od desetaka lekcija prilagođenih vašim potrebama.",
          },
          {
            image: screen3,
            category: "Zadaci",
            description:
              "Naše vježbe učinkovito podupiru ponavljanje gradiva i poboljšavaju pamćenje.",
          },
          {
            image: screen4,
            category: "Riječi, fraze i izrazi",
            description:
              "Svaka lekcija sadrži pažljivo odabrane slike koje čine proces učenja intuitivnim.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Engleski" },
          { image: new_french, lang: "Francuski" },
          { image: new_spanish, lang: "Španjolski" },
          { image: new_niderland, lang: "Nizozemski" },
          { image: new_germany, lang: "Njemački" },
          { image: new_italy, lang: "Talijanski" },
          { image: new_russia, lang: "Ruski" },
          { image: new_portugal, lang: "Portugalski" },
          { image: new_polish, lang: "Poljski" },
        ],
        title: "Dostupni jezici:",
        description:
          "Pripremite se za sljedeće putovanje ili dodajte jezične vještine svom životopisu.",
      },
      expertSection: {
        title: "Vaš jezični ekspert",
        firstSectionTitle: "Podrška nadohvat ruke",
        firstSectionDescription: "Dobijte potpunu podršku tijekom učenja!",
        secondSectionTitle: "Postavljajte pitanja i dobivajte odgovore",
        secondSectionDescription:
          "Bilo koje pitanje možete postaviti izravno svom stručnjaku.",
        thirdSectionTitle: "Individualan pristup na svakom koraku",
        thirdSectionDescription:
          "Korištenje savjeta jezičnog stručnjaka ubrzava vaše učenje.",
      },
      courseValue: {
        title: "Dobijte više s eLingo",
        subtitle: "Ne samo učenje jezika – dobivate potpuni pristup alatima.",
        buttonText: "Naručite sada!",
        config: [
          {
            title: "Učenje prilagođeno vašem životnom stilu",
            sections: [
              {
                text: "Učite gdje želite i kada želite zahvaljujući našoj platformi.",
              },
              {
                text: "Kratke lekcije savršeno se uklapaju u vaš dnevni raspored.",
              },
            ],
          },
          {
            title: "Motivacija na svakom koraku",
            sections: [
              {
                text: "Održavajte svoju motivaciju putem našeg sustava praćenja.",
              },
              {
                text: "Svaki dan učenja vas približava tečnoj komunikaciji.",
              },
            ],
          },
          {
            title: "Potpuna fleksibilnost i personalizacija",
            sections: [
              {
                text: "Prilagodite tečaj svojoj razini i tempu učenja.",
              },
              {
                text: "Usredotočite se na ono što vas najviše zanima.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Misija",
      description: `Naš krajnji cilj u eLingo je omogućiti svakome pristup osobnom učitelju putem tehnologije. Vjerujemo da je jezik most između kultura i naša je misija prevladati komunikacijske barijere i zbližiti ljude kroz jezično obrazovanje.`,
    },
    why: {
      title: "Zašto mi?",
      config: [
        {
          title: "Inovativne metode poučavanja",
          image: dlaczego1,
          description:
            "Naš sofisticirani i u praksi provjereni algoritam učenja prilagođava materijale za učenje vašoj trenutnoj razini znanja i proširuje ga kroz učinkovito učenje",
        },
        {
          title: "Širok raspon jezika",
          image: dlaczego2,
          description:
            "Nudimo tečajeve na svjetski najraširenijim jezicima. To vam omogućuje da odaberete jezik koji odgovara vašim potrebama.",
        },
        {
          title: "Obrazovanje na zabavan način",
          image: dlaczego3,
          description:
            "Naša platforma čini učenje jezika zabavnim i ugodnim iskustvom, tako da ćete biti motivirani da ostanete s njim.            ",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizacija i pristup",
          description:
            "Naš moćni algoritam strojnog učenja neprestano radi na pružanju materijala za učenje na odgovarajućoj razini za svakog pojedinca. eLingo omogućuje učenicima da sami pronađu obrasce bez potrebe da se fokusiraju na pravila jezika - na isti način na koji ste učili svoj materinji jezik kao dijete.",
          image: zdjecie1,
        },
        {
          title: "Odvažna kombinacija tehnologije i znanosti          ",
          description:
            "eLingo koristi odvažnu kombinaciju tehnologije i znanosti kako bi potaknuo vaš um na akciju. Ponavljanje bezbrojnih popisa riječi i fraza dosadno je i vaš mozak to zna. Naša metoda, koja vam pomaže da stečeno znanje koristite na različite načine u određenim trenucima, je uzbudljiva i učinkovita - vaš mozak će to cijeniti!",
          image: zdjecie2,
        },
        {
          title: "Zajednica i vrijednost",
          description:
            "Jezici pomažu ljudima da budu aktivni. Učenje jezika potiče razumijevanje. eLingo zajednica okuplja ljude iz cijelog svijeta. Ambiciozni smo i uvijek pomičemo granice. Vjerujemo da bi učenje jezika trebalo biti zabavno i radoznali smo o istraživanju novih kultura kroz jezike.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Da li ste zainteresirani tečajem?",
      title1: "Trebate više informacija?",
      subtitle: "Ostavite nam svoj broj - nazvat ćemo vas",
      subtitle1:
        "If you already have an account, please fill in the following fields:",
      button1: "Ime i prezime",
      button2: "Broj telefona",
      button3: "E-mail",
      buttonSub: "Zatražite poziv",
      checkOne:
        "Slanjem ovog obrasca slažem se s uvjetima i izjavljujem da sam pročitao uvjete i pravila zaštite podataka.        ",
      checkOneTwo: "the",
      checkTwo: "Slažem se da me se kontaktira u marketinške svrhe",
      textOne: "Obavještavamo vas da",
      textTwo:
        "Administrator Vaših osobnih podataka je EOU Sp. z o.o., sa sjedištem u Varšavi, ul. Młynarska 42 / 115, 01-171, registrirano u registru poduzetnika koji vodi okružni sud u Varšavi pod KRS 0001038089, REGON: 525396900, NIP 5273057993. Vaše osobne podatke obrađivati ćemo u vezi s upitom putem kontakt forme ili izvršenjem sklopljenog ugovora. Obrativši nam se, dobrovoljno pristajete na obradu Vaših osobnih podataka u gore navedene svrhe. Imate pravo pristupiti, ispraviti, izbrisati ili ograničiti obradu svojih podataka, prigovoriti obradi i prenosivosti podataka. Također imate pravo povući svoju privolu u bilo kojem trenutku, ali povlačenje privole ne utječe na zakonitost obrade temeljene na privoli prije njenog povlačenja. Također imate pravo podnijeti pritužbu nadzornom tijelu. Pružanje podataka je dobrovoljno, ali može biti potrebno za odgovor na upit ili izvršenje ugovora. Osobni podaci bit će pohranjeni onoliko koliko je potrebno za gore navedene svrhe, ali ne dulje od roka zastare potraživanja prema općem pravu. Molimo pažljivo pročitajte politiku privatnosti. ",
      buttonMore: "Čitaj više",
      buttonLess: "Čitaj manje",
      subOne: "uvjeti pružanja usluge",
      subTwo: "i pravila privatnosti.",
      buttonTXT: "Thank you",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "eh",
      market: "hr",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "hr-en",
      server_link: "https://cms.eou-edu.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    title: "Pridružite nam se na:",
    privacy: "Privatnost",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Uvjeti pružanja usluge",
    rulesLink: "/Regulations_EN.pdf",
  },
};
