import Store from '../../../../../../Store'
import sk from './images/sk.jpg'
import hu from './images/hu.jpg'
import hr from './images/hr.png'
import lt from './images/lt.png'
import ro from './images/ro.png'
import cs from './images/cs.png'
import lv from './images/lv.png'

export const mockImages = {
    'sk': sk,
    'hu': hu,
    'hr': hr,
    lt,
    ro,
    cs,
    lv
}

export const getMockImage = () => {
    const short = Store.getUserPlatformLangShort();
    return mockImages[short]
}