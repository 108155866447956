import React from "react";

import styled from "styled-components";
import { Colors } from "../../product/colors/Colors";
import Facebook from "../assets/Facebook.svg";
import Youtube from "../assets/Youtube.svg";
import Instagram from "../assets/Instagram.svg";
import { Link } from "react-router-dom";

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;
  font-weight: bold;
  background-color: ${Colors.mainColor};
  margin-top: 10px;

  @media (max-width: 600px) {
    gap: 10px;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Flex2 = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 600px) {
    justify-content: center;
    gap: 20px;
  }
`;

const CustomLink = styled.a`
  color: white;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 400;
  transition: 0.35s ease;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: white;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: 0.3s ease-in-out 0s;
    transition: 0.3s ease-in-out 0s;
  }

  &:hover {
    transform: scaleX(1.1);
    color: white;

    &::before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
const LogoBox = styled.div`
  height: 80px;
  overflow: hidden;
`;

const CpoyWrapper = styled.div`
  color: white;
  font-size: 13px;
  font-family: roboto;
  font-weight: normal;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const Text = styled.p`
  color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 38px;
`;

const IconWrapper = styled(Link)`
  width: 24px;

  div {
    width: 100%;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 17px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 18px 0;
  width: 80vw;

  @media (max-width: 600px) {
    justify-content: center;
    gap: 20px;
    width: 100vw;
  }
`;

const Border = styled.div`
  width: 336px;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
  margin-bottom: -15px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const ICONS = [
  { icon: Youtube, url: "https://www.youtube.com/@elingo-edu" },
  { icon: Facebook, url: "https://www.facebook.com/elingoedu" },
  { icon: Instagram, url: "https://www.instagram.com/elingo_edu/" },
];

const Footer = ({ data }) => {
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <TopWrapper>
        <Text>{data.title}</Text>
        <IconsWrapper>
          {ICONS.map((ic) => (
            <IconWrapper to={ic.url}>
              <img src={ic.icon} />
            </IconWrapper>
          ))}
        </IconsWrapper>
      </TopWrapper>
      <Border />
      <BottomWrapper>
        <CpoyWrapper>© {year} by EOU</CpoyWrapper>
        <Flex2>
          <CustomLink href={data.rulesLink} target="_blank">
            {data.rules}
          </CustomLink>
          <CustomLink href={data.privacyLink} target="_blank">
            {data.privacy}
          </CustomLink>
        </Flex2>
      </BottomWrapper>
    </Wrapper>
  );
};

export default Footer;
