import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen3 from "../images/newProductPage/slovak/1.png";
import screen4 from "../images/newProductPage/slovak/2.png";
import screen5 from "../images/newProductPage/slovak/3.png";
import screen6 from "../images/newProductPage/slovak/4.png";
import screen1 from "../images/newProductPage/slovak/5.png";
import screen2 from "../images/newProductPage/slovak/6.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const slovak = {
  navbar: {
    data: [
      { title: "Objednať kurz", path: "/course" },
      { title: "Prihlásiť sa", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "sk",
      hero: {
        languages: [
          "v angličtine",
          "v španielčine",
          "v nemčine",
          "v holandčine",
          "v taliančine",
          "v ruštine",
          "v poľštine",
          "vo francúzštine",
          "v portugalčine",
        ],
        title: "Hovorte sebavedomejšie,",
        prefix: "s istotou",
        subtitle:
          "Získajte jazykové zručnosti prostredníctvom nášho kurzu založeného na príkladoch z reálneho života!",
        buttonText: "Objednať teraz!",
      },
      learning: {
        title: "Ako vyzerá kurz?",
        config: [
          {
            image: screen1,
            category: "Naučte sa jazyk najjednoduchším spôsobom",
            descriptions: [
              {
                bold: "Desiatky lekcií",
                rest: "s príkladmi založenými na každodennej komunikácii.",
              },
              {
                bold: "Praktické frázy",
                rest: "navrhnuté rodenými hovorcami.",
              },
              {
                bold: "Cvičenia,",
                rest: "ktoré efektívne rozvíjajú čítanie, počúvanie a rozprávanie.",
              },
            ],
          },
          {
            image: screen2,
            category: "Praktické lekcie",
            description:
              "Každá úroveň výučby sa skladá z desiatok lekcií prispôsobených vašim potrebám.",
          },
          {
            image: screen3,
            category: "Úlohy",
            description:
              "Naše cvičenia efektívne podporujú opakovanie materiálu a zlepšujú pamäť.",
          },
          {
            image: screen4,
            category: "Slová, frázy a výrazy",
            description:
              "Každá lekcia obsahuje starostlivo vybrané obrázky, ktoré robia proces učenia intuitívnym.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angličtina" },
          { image: new_french, lang: "Francúzština" },
          { image: new_spanish, lang: "Španielčina" },
          { image: new_niderland, lang: "Holandčina" },
          { image: new_germany, lang: "Nemčina" },
          { image: new_italy, lang: "Taliančina" },
          { image: new_russia, lang: "Ruština" },
          { image: new_portugal, lang: "Portugalčina" },
          { image: new_polish, lang: "Poľština" },
        ],
        title: "Dostupné jazyky:",
        description:
          "Pripravte sa na svoju ďalšiu cestu alebo pridajte jazykové zručnosti do svojho životopisu.",
      },
      expertSection: {
        title: "Váš jazykový expert",
        firstSectionTitle: "Podpora na dosah ruky",
        firstSectionDescription: "Získajte plnú podporu pri štúdiu!",
        secondSectionTitle: "Pýtajte sa a dostávajte odpovede",
        secondSectionDescription:
          "Akékoľvek otázky môžete položiť priamo svojmu expertovi.",
        thirdSectionTitle: "Individuálny prístup na každom kroku",
        thirdSectionDescription:
          "Využitie rád jazykového experta urýchli vaše učenie.",
      },
      courseValue: {
        title: "Získajte viac s eLingo",
        subtitle: "Nielen jazykové učenie – získajte plný prístup k nástrojom.",
        buttonText: "Objednať teraz!",
        config: [
          {
            title: "Výučba prispôsobená vášmu životnému štýlu",
            sections: [
              {
                text: "Učte sa, kde chcete a kedy chcete, vďaka platforme dostupnej na rôznych zariadeniach.",
              },
              {
                text: "Krátke lekcie dokonale zapadnú do vášho každodenného harmonogramu.",
              },
            ],
          },
          {
            title: "Motivácia na každom kroku",
            sections: [
              {
                text: "Udržujte svoju sériu dní učenia vďaka nášmu systému sledovania.",
              },
              {
                text: "Každý deň učenia je krok bližšie k jazykovej plynulosti.",
              },
            ],
          },
          {
            title: "Plná flexibilita a personalizácia",
            sections: [
              {
                text: "Prispôsobte si kurz svojej úrovni a tempu učenia.",
              },
              {
                text: "Zamerajte sa na to, čo vás najviac zaujíma.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Poslanie",
      description: `Veríme, že jazyk tvorí most medzi kultúrami a našou misiou je prekonávať komunikačné bariéry a spájať ľudí prostredníctvom vzdelávania v oblasti jazykov.`,
    },
    why: {
      title: "Prečo My?",
      config: [
        {
          title: "Inovatívne Vzdelávacie Metódy",
          image: dlaczego1,
          description:
            "Náš sofistikovaný a v praxi overený algoritmus učenia prispôsobuje materiály vašej aktuálnej úrovni znalostí a rozširuje ju prostredníctvom efektívneho vyučovania.",
        },
        {
          title: "Široká Škála Jazykov",
          image: dlaczego2,
          description:
            "Ponúkame kurzy vo svetovo najpopulárnejších jazykoch. To vám umožňuje vybrať si jazyk podľa vašich potrieb.",
        },
        {
          title: "Vzdelávanie Zábavnou Formou",
          image: dlaczego3,
          description:
            "Naša platforma robí z učenia jazyka zábavný a príjemný zážitok, takže budete motivovaní učiť sa jazyky.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizácia a Prístupnosť",
          description:
            "Náš efektívny algoritmus strojového učenia neustále pracuje na poskytovaní materiálov vhodných pre každého jednotlivca. eLingo umožňuje študentom objavovať vzory samostatne, bez potreby zameriavať sa na pravidlá jazyka - presne ako ste sa naučili svoj materinský jazyk v detstve.",
          image: zdjecie1,
        },
        {
          title: "Kombinácia Technológie a Vedy",
          description:
            "eLingo využíva odvážnu kombináciu technológie a vedy na stimuláciu mysle do akcie. Opakovanie nespočetných zoznamov slov a fráz je nudné a váš mozog to vie. Naša metóda je vzrušujúca a efektívna, núti vás používať získané vedomosti rôznymi spôsobmi v konkrétnych momentoch - váš mozog ocení toto riešenie!",
          image: zdjecie2,
        },
        {
          title: "Spoločenstvo a hodnoty",
          description:
            "Jazyky pomáhajú ľuďom zbližovať sa. Učenie sa jazykov uľahčuje porozumenie. Komunita eLingo spája ľudí z celého sveta. Sme ambiciózni a neustále posúvame hranice. Veríme, že učenie sa jazykov môže byť zábavné, a sme zvedaví na spoznávanie nových kultúr prostredníctvom jazyka.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Máte záujem o kurz?  ",
      title1: "Chcete získať viac informácií?",
      subtitle: "Zanechajte svoje telefónne číslo",
      subtitle1: "Zavoláme vám späť.",
      button1: "Meno a priezvisko",
      button2: "Telefónne číslo",
      button3: "Email",
      buttonSub: "Odoslať",
      checkOne:
        "Odoslaním tohto formulára súhlasím s podmienkami a vyhlasujem, že som si prečítal/a nasledujúce informácie",
      checkOneTwo: "the",
      checkTwo: "Súhlasím s kontaktovaním na marketingové účely",
      textOne: "Dovoľujeme si vás informovať, že",
      textTwo:
        "Správcom vašich osobných údajov je spoločnosť  EOU Sp. z o.o., so sídlom vo Varšave, Młynarska 42 / 115, 01-171, zapísaná v registri podnikateľov vedenom okresným súdom vo Varšave pod číslom KRS5273057993, REGON: 525396900, NIP5273057993. Vaše osobné údaje budú spracúvané v súvislosti s dopytom prostredníctvom kontaktného formulára alebo s plnením uzatvorenej zmluvy. Kontaktovaním nás dobrovoľne súhlasíte so spracovaním vašich osobných údajov na vyššie uvedené účely. Máte právo na prístup k svojim údajom, ich opravu, vymazanie alebo obmedzenie spracovania, právo namietať proti spracovaniu a právo na prenosnosť údajov. Máte tiež právo svoj súhlas kedykoľvek odvolať, ale odvolanie súhlasu nemá vplyv na zákonnosť spracovania založeného na súhlase pred jeho odvolaním. Máte tiež právo podať sťažnosť dozornému orgánu. Poskytnutie údajov je dobrovoľné, ale môže byť nevyhnutné na zodpovedanie žiadosti alebo na plnenie zmluvy. Osobné údaje sa budú uchovávať po dobu nevyhnutnú na vyššie uvedené účely, nie však dlhšie, ako je doba premlčania nárokov podľa všeobecných právnych predpisov. Pozorne si prečítajte zásady ochrany osobných údajov",
      buttonMore: "Prečítajte si viac",
      buttonLess: "Čítajte menej",
      subOne: "podmienky",
      subTwo: "a pravidlá ochrany osobných údajov.",
      buttonTXT: "Ďakujem",
      rulesLink: "/Regulations_SK.pdf",
      privacyLink: "/Privacy_SK.pdf",
      product: "es",
      market: "sk",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "sk-en",
      server_link: "https://cms.eou-edu.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    title: "Pridajte sa k nám na:",
    privacy: "Ochrana osobných údajov",
    privacyLink: "/Privacy_SK.pdf",
    rules: "Podmienky služby",
    rulesLink: "/Regulations_SK.pdf",
  },
};
