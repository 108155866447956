import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen1 from "../images/newProductPage/romanian/1.png";
import screen2 from "../images/newProductPage/romanian/2.png";
import screen3 from "../images/newProductPage/romanian/3.png";
import screen4 from "../images/newProductPage/romanian/4.png";
import screen5 from "../images/newProductPage/romanian/5.png";
import screen6 from "../images/newProductPage/romanian/6.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const romanian = {
  navbar: {
    data: [
      { title: "Comandă un Curs", path: "/course" },
      { title: "Autentificare", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "Despre Noi", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "ro",
      hero: {
        languages: [
          "în engleză",
          "în spaniolă",
          "în germană",
          "în olandeză",
          "în italiană",
          "în rusă",
          "în poloneză",
          "în franceză",
          "în portugheză",
        ],
        title: "Vorbiți mai curajos,",
        prefix: "cu încredere",
        subtitle:
          "Dobândește abilități lingvistice prin cursul nostru bazat pe exemple din viața reală!",
        buttonText: "Comandă acum!",
      },
      learning: {
        title: "Cum arată cursul?",
        config: [
          {
            image: screen1,
            category: "Învață limba în cel mai simplu mod",
            descriptions: [
              {
                bold: "Zeci de lecții",
                rest: "cu exemple bazate pe comunicarea zilnică.",
              },
              {
                bold: "Fraze practice",
                rest: "concepute de vorbitori nativi.",
              },
              {
                bold: "Exerciții,",
                rest: "care dezvoltă eficient cititul, ascultarea și vorbitul.",
              },
            ],
          },
          {
            image: screen2,
            category: "Lecții practice",
            description:
              "Fiecare nivel de învățare este format din zeci de lecții adaptate nevoilor tale.",
          },
          {
            image: screen3,
            category: "Teme",
            description:
              "Exercițiile noastre susțin eficient repetarea materialului și îmbunătățesc memoria.",
          },
          {
            image: screen4,
            category: "Cuvinte, fraze și expresii",
            description:
              "Fiecare lecție include imagini atent selectate, care fac procesul de învățare intuitiv.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Engleză" },
          { image: new_french, lang: "Franceză" },
          { image: new_spanish, lang: "Spaniolă" },
          { image: new_niderland, lang: "Olandeză" },
          { image: new_germany, lang: "Germană" },
          { image: new_italy, lang: "Italiană" },
          { image: new_russia, lang: "Rusă" },
          { image: new_portugal, lang: "Portugheză" },
          { image: new_polish, lang: "Poloneză" },
        ],
        title: "Limbi disponibile:",
        description:
          "Pregătește-te pentru următoarea călătorie sau adaugă abilități lingvistice în CV-ul tău.",
      },
      expertSection: {
        title: "Expertul tău lingvistic",
        firstSectionTitle: "Suport la îndemână",
        firstSectionDescription: "Primește suport complet pe durata învățării!",
        secondSectionTitle: "Pune întrebări și primește răspunsuri",
        secondSectionDescription:
          "Poți pune orice întrebare direct expertului tău.",
        thirdSectionTitle: "Abordare individuală la fiecare pas",
        thirdSectionDescription:
          "Folosirea sfaturilor expertului lingvistic accelerează procesul tău de învățare.",
      },
      courseValue: {
        title: "Obține mai mult cu eLingo",
        subtitle:
          "Nu doar învățarea limbilor – obține acces complet la instrumente.",
        buttonText: "Comandă acum!",
        config: [
          {
            title: "Învățare adaptată stilului tău de viață",
            sections: [
              {
                text: "Învățați oriunde și oricând doriți, datorită platformei noastre.",
              },
              {
                text: "Lecțiile scurte se potrivesc perfect în programul tău zilnic.",
              },
            ],
          },
          {
            title: "Motivație la fiecare pas",
            sections: [
              {
                text: "Păstrează-ți motivația cu sistemul nostru de urmărire.",
              },
              {
                text: "Fiecare zi de învățare te aduce mai aproape de fluență.",
              },
            ],
          },
          {
            title: "Flexibilitate totală și personalizare",
            sections: [
              {
                text: "Adaptează cursul la nivelul tău și ritmul de învățare.",
              },
              {
                text: "Concentrează-te pe ceea ce te interesează cel mai mult.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Misiune",
      description: `Credem că limba formează un pod între culturi, iar misiunea noastră este să depășim barierele de comunicare și să aducem oamenii împreună prin educație lingvistică.`,
    },
    why: {
      title: "De Ce Noi?",
      config: [
        {
          title: "Metode Educaționale Inovatoare",
          image: dlaczego1,
          description:
            "Algoritmul nostru de învățare sofisticat și testat în practică ajustează materialele la nivelul actual de cunoștințe și îl extinde prin predare eficientă.",
        },
        {
          title: "O Gamă Largă de Limbi",
          image: dlaczego2,
          description:
            "Oferim cursuri în cele mai populare limbi ale lumii. Acest lucru îți permite să alegi limba care se potrivește nevoilor tale.",
        },
        {
          title: "Educație într-un Mod Distractiv",
          image: dlaczego3,
          description:
            "Platforma noastră face învățarea limbilor o experiență distractivă și plăcută, astfel încât vei fi motivat să înveți limbi.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizare și Accesibilitate",
          description:
            "Algoritmul nostru eficient de învățare automată lucrează constant pentru a oferi materiale de nivel adecvat pentru fiecare individ. eLingo permite cursanților să descopere modele pe cont propriu fără a trebui să se concentreze pe regulile limbii - exact așa cum ai învățat limba maternă în copilărie.",
          image: zdjecie1,
        },
        {
          title: "Combinarea Tehnologiei și Științei",
          description:
            "eLingo folosește o combinație îndrăzneață de tehnologie și știință pentru a stimula mintea la acțiune. Repetarea nenumăratelor liste de cuvinte și expresii este plictisitoare, iar creierul tău știe asta. Metoda noastră este captivantă și eficientă, forțându-te să folosești cunoștințele dobândite în diferite moduri în momente specifice - creierul tău va aprecia această soluție!",
          image: zdjecie2,
        },
        {
          title: "Comunitate și Valori",
          description:
            "Limbile ajută oamenii să se angajeze. Învățarea limbilor facilitează înțelegerea. Comunitatea eLingo aduce împreună oameni din întreaga lume. Suntem ambițioși și mereu împingem limitele. Credem că învățarea limbilor poate fi distractivă și suntem curioși să explorăm noi culturi prin limbă.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interesat de curs? ",
      title1: "Doriți mai multe informații?",
      subtitle: "Lăsați-vă numărul de telefon - vă vom suna înapoi.",
      subtitle1:
        "Dacă aveți deja un cont, vă rugăm să completați următoarele câmpuri:",
      button1: "Nume și Prenume",
      button2: "Număr de Telefon",
      button3: "Email",
      buttonSub: "Trimite",
      checkOne:
        "Prin trimiterea acestui formular, accept termenii și declar că am citit următorul",
      checkOneTwo: "documentul",
      checkTwo: "Consimt să fiu contactat în scopuri de marketing",
      textOne: "Vă informăm că",
      textTwo:
        "Operatorul datelor dvs. personale este EOU Sp. z o.o., cu sediul în Varșovia, Młynarska 42 / 115, 01-171, înregistrată în registrul antreprenorilor ținut de tribunalul districtual din Varșovia sub KRS5273057993, REGON: 525396900, NIP5273057993. Datele dvs. personale vor fi prelucrate în legătură cu solicitarea formulată prin formularul de contact sau executarea contractului încheiat. Contactându-ne, consimțiți voluntar la prelucrarea datelor dvs. personale în scopurile menționate anterior. Aveți dreptul de a accesa, corecta, șterge sau restricționa prelucrarea datelor dvs., de a vă opune prelucrării și de a portabilității datelor. De asemenea, aveți dreptul de a vă retrage consimțământul în orice moment, dar retragerea consimțământului nu afectează legalitatea prelucrării bazate pe consimțământ înainte de retragerea acestuia. De asemenea, aveți dreptul de a depune o plângere la autoritatea de supraveghere. Furnizarea datelor este voluntară, dar poate fi necesară pentru a răspunde la o solicitare sau pentru executarea unui contract. Datele personale vor fi stocate pentru perioada necesară scopurilor de mai sus, dar nu mai mult decât perioada de prescripție a revendicărilor conform dreptului comun. Vă rugăm să citiți cu atenție politica de confidențialitate",
      buttonMore: "Citește mai mult",
      buttonLess: "Citește mai puțin",
      subOne: "termeni",
      subTwo: "și reguli de confidențialitate.",
      buttonTXT: "Mulțumesc",
      rulesLink: "/Regulations_RO.pdf",
      privacyLink: "/Privacy_RO.pdf",
      product: "er",
      market: "ro",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "ro-en",
      server_link: "https://cms.eou-edu.eu/integration/lead-from-landing",
      currency: "ron",
      price: 390,
    },
  },
  footer: {
    title: "Alătură-te nouă la:",
    privacy: "Privacy",
    privacyLink: "/Privacy_RO.pdf",
    rules: "Termeni de serviciu",
    rulesLink: "/Regulations_RO.pdf",
  },
};
